var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { ReactComponent as FiltersIcon } from '@icons/wolfkit-light/filter-light.svg';
import { ReactComponent as XmarkIconComponent } from '@icons/wolfkit-light/xmark-light.svg';
import ButtonCustom from '../../../../../shared/ui/buttons/ButtonCustom';
const Button = styled(ButtonCustom, {
    shouldForwardProp: propName => propName !== 'isActive',
})(props => ({
    color: props.isActive ?
        `${props.theme.palette.primary.main}` :
        `${props.theme.palette.text.secondary}`,
    borderColor: `${props.isActive ? props.theme.palette.primary.main : props.theme.customColors.input.border}`,
}));
const XmarkIcon = styled(XmarkIconComponent)(props => ({
    width: '16px',
    height: '16px',
    '& path': {
        fill: `${props.theme.palette.primary.main}`,
    },
}));
const FilterIcon = ({ isActive = undefined, }) => (isActive ? _jsx(XmarkIcon, {}) : _jsx(FiltersIcon, {}));
const FilterButton = (_a) => {
    var { isActive = undefined } = _a, props = __rest(_a, ["isActive"]);
    const { t } = useTranslation();
    return (_jsx(Button
    // eslint-disable-next-line react/jsx-props-no-spreading
    , Object.assign({}, props, { isActive: isActive, startIcon: _jsx(FilterIcon, { isActive: isActive }), variant: 'outlined', children: isActive ?
            t('marketplace.all_portfolio_view.filters.show_filters_active_btn', { ns: 'common' }) :
            t('marketplace.all_portfolio_view.filters.show_filters_btn', { ns: 'common' }) })));
};
export default FilterButton;
